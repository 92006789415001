import React from "react"
import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import TextSectionBackground from "../components/TextSectionBackground"
import { ActionButton } from "../components/buttons"
import { ButtonBasic } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import GetTheSpecialButton from "../components/GetTheSpecialButton"
import { PageHeader } from "../components/Headings 1"
import { SubSectionHeader } from "../components/Headings 2"
import styled from "styled-components"
import SEO from "../components/SEO"

const SpecialsList = styled.div`
  max-width: 1240px;
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
  h3 {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    color: black;
    font-weight: 400;
    font-size: 28px;
    margin: 0 0 10px;
  }
  h4 {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-size: 22px;
    font-weight: 400;
    color: #3e3e3e;
    margin: 0;
  }
  h5 {
    text-align: left;
    color: #666;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 700;
    font-size: 28px;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
  }
  p {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    color: #666;
    font-weight: 500;
    line-height: 1.2em;
    font-size: 17px;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
  }
  .image-wrapper {
    @media all and (max-width: 520px) {
      width: 100% !important;
    }
  }
  .grow {
    border: none;
    background: none;
    position: relative;
    &:hover {
      picture {
        img {
          transform: scale(1.1);
          filter: saturate(1.3);
        }
      }
    }

    picture {
      img {
        width: 450px;
        cursor: pointer;
        transition: transform 0.5s, filter 0.25s !important;
        &:hover {
          transform: scale(1.1);
          filter: saturate(1.3);
        }
        @media all and (max-width: 520px) {
          width: 100%;
        }
      }
    }
  }

  @media all and (max-width: 880px) {
    flex-wrap: wrap;
  }
`

const SpecialWrapper = styled.div`
  display: flex;
  text-align: left;
  .detcol {
    width: 100%;
    flex-grow: 2;
    margin: 20px 0 20px 40px;
    display: flex;
    flex-direction: column;
  }
  .imgcol {
    width: 550px;
  }
  p {
    text-align: left;
  }
  @media all and (max-width: 880px) {
    flex-wrap: wrap;
    .imgcol {
      width: calc(100% - 20px);
    }
  }
`

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  text-align: center;
  margin: 20px;
  .detcol {
    flex: grow;
    padding: 10px;
    button {
      min-width: unset;
      font-size: 14px;
    }
  }
  h3 {
    text-align: center;
  }
  p {
    margin-top: 0;
  }

  @media all and (max-width: 880px) {
    width: 100%;
  }
`

const Intro = styled.div`
  max-width: 1000px;
  margin: 0 auto 3rem;
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-size: 17px;
  line-height: 1.2em;
  text-align: left;
  color: #666;
`

const Thumbnail = styled.div`
  width: calc(100% - 20px);
  position: relative;
  margin: 10px;
  @media all and (max-width: 520px) {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  button {
    margin: 8px 10px;
  }
  margin-left: -8px;
  margin-top: auto;
`

const Accommodation = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSpecial {
        nodes {
          header
          slug
          price {
            price
          }
          teaserDesc {
            teaserDesc
          }
          picture {
            fluid(maxWidth: 450) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          discount
          forLocals
        }
      }
    }
  `)

  return (
    <PageLayout view={true}>
      <SEO title="Specials" description="Specials at Mavela Game Lodge" />
      <TextSectionBackground pa="4% 5% 1rem">
        <PageHeader>Direct Booking Specials</PageHeader>
        <Intro>
          <p>
            We're excited to welcome you back to the incredible experiences
            South Africa has to offer, and in particular the unique
            opportunities to see rare and endangered wildlife species at Mavela
            Lodge. In this spirit we've put together some special offers to
            welcome you back in style...
          </p>
        </Intro>

        <SpecialsList>
          {data.allContentfulSpecial.nodes
            .filter(
              (special) => special.discount != 1 && special.forLocals != 1
            )
            .map((special: any, i: number) => (
              <div key={special.header}>
                <SpecialWrapper>
                  <div className="imgcol">
                    <Link to={`/special/${special.slug}`} className="grow">
                      <Thumbnail>
                        <Img
                          fluid={special.picture.fluid}
                          className="image-wrapper"
                        ></Img>
                      </Thumbnail>
                    </Link>
                  </div>
                  <div className="detcol">
                    <h3>{special.header}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: "<h4>" + special.price.price + "</h4>",
                      }}
                    />
                    <p>{special.teaserDesc.teaserDesc}</p>

                    <ButtonWrapper>
                      <ActionButton>
                        <a
                          href={`/special/${special.slug}`}
                          rel="noopener noreferrer"
                          target="self"
                        >
                          More Info
                        </a>
                      </ActionButton>
                      <GetTheSpecialButton
                        slug={special.header}
                      ></GetTheSpecialButton>
                    </ButtonWrapper>
                  </div>
                </SpecialWrapper>
              </div>
            ))}
        </SpecialsList>
      </TextSectionBackground>
      <TextSectionBackground pa="1rem 5% 4rem">
        <SpecialsList>
          {data.allContentfulSpecial.nodes
            .filter((special) => special.discount == 1)
            .map((special: any, i: number) => (
              <DiscountWrapper>
                <div key={special.header}>
                  <h3>{special.header}</h3>
                  <div className="imgcol">
                    <Link to={`/special/${special.slug}`} className="grow">
                      <Thumbnail>
                        <Img
                          fluid={special.picture.fluid}
                          className="image-wrapper"
                        ></Img>
                      </Thumbnail>
                    </Link>
                  </div>
                  <div className="detcol">
                    <p>{special.teaserDesc.teaserDesc}</p>
                    <ButtonWrapper>
                      <ActionButton>
                        <a
                          href={`/special/${special.slug}`}
                          rel="noopener noreferrer"
                          target="self"
                        >
                          More Info
                        </a>
                      </ActionButton>
                      <GetTheSpecialButton
                        slug={special.header}
                      ></GetTheSpecialButton>
                    </ButtonWrapper>
                  </div>
                </div>
              </DiscountWrapper>
            ))}
        </SpecialsList>
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Accommodation
